import { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import EditJson from "./EditJson";
import { Download } from "../../components/Download";
import axios from "axios";
import parse from "html-react-parser";
import { IconChevronDown } from "@tabler/icons-react";
import CalculatorPage from "../../components/Calculator";

export const ViewJson = ({
  isViewModalOpen,
  onClose,
  uploadedFileName,
}: {
  isViewModalOpen?: boolean;
  onClose: () => void;
  uploadedFileName?: string;
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const [jsonData, setJsonData] = useState("");
  const [isViewHtml, setIsViewHtml] = useState(false);
  const [htmlData, setHtmlData] = useState("");
  const [selectedOption, setSelectedOption] = useState<string>("");

  useEffect(() => {
    setSelectedOption('')
    if (isViewModalOpen) {
      setIsModalOpen(true);
      previewFile("appended");
    } else {
      setIsModalOpen(false);
    }
    if (uploadedFileName) {
      console.log("uploadedFileName", uploadedFileName);
      const fileName = uploadedFileName.split(".")[0];
      setFileName(fileName);
      console.log(fileName);
    }
    
  }, [apiUrl, fileName, isViewModalOpen, uploadedFileName]);

  const previewFile = (version: string) => {
    axios.get(`${apiUrl}/json/view/${fileName + ".json"}`, {
        params: {
          version: version,
        },
      })
      .then((response) => {
        setJsonData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleEditJson = () => {
    setIsEditModalOpen(true);
  };

  const handleClosePopup = () => {
    setIsEditModalOpen(false);
    setIsModalOpen(false);
    if (onClose) onClose();
  };

  const handleCancel = () => {
    setIsEditModalOpen(false);
  };

  const handleSave = (parsedData: any) => {
    setJsonData(parsedData);
    setIsEditModalOpen(false);
  };

  const handleDownloadJSON = async () => {
  const fileName = uploadedFileName?.split(".")[0] + ".json";
   if(selectedOption === "original_json"){
    await axios.get(`${apiUrl}/json/download/${fileName}`, {
      params: {
         version:'original'
      },
    })
    .then((response) => {
      Download(response.data, fileName);
    })
    .catch(function (error) {
      console.log(error);
    });
   }else if(selectedOption === "appended_json" || selectedOption===''){
    await axios.get(`${apiUrl}/json/download/${fileName}`, {
      params: {
         version:'appended'
      },
    })
    .then((response) => {
      Download(response.data, fileName);
    })
    .catch(function (error) {
      console.log(error);
    });
     }
}

  const handleDownloadPDF = async () => {
    const fileName = uploadedFileName?.split(".")[0] + ".pdf";
    const response = await axios.get(`${apiUrl}/download/pdf/${fileName}`, {
          responseType: 'blob', 
        });
        if (response.headers['content-type'] === 'application/pdf') {
          const file = new Blob([response.data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = response.headers['content-disposition']
            ? response.headers['content-disposition']?.split('filename=')[1]?.replace(/['"]/g, '') 
            : fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(fileURL);
        } else {
          console.error('Unexpected content type:', response.headers['content-type']);
        }
  };

  const handleViewHtml = async () => {
    setIsViewHtml(true);
    const fileName = uploadedFileName?.split(".")[0] + ".json";
    await axios.get(`${apiUrl}/view/json_to_html/${fileName}`)
      .then((response) => {
        console.log(response);
        setHtmlData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    await axios.get(`${apiUrl}/view/html_to_pdf/${fileName}`)
      .then((response) => {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedOption(value);

    switch (value) {
      case "html_view":
        handleViewHtml();
        setIsViewHtml(true);
        break;
      case "original_json":
        previewFile("original");
        setIsViewHtml(false);
        break;
      case "appended_json":
        previewFile("appended");
        setIsViewHtml(false);
        break;
      default:
        setIsViewHtml(false);
        setSelectedOption('');
        break;
    }
  };

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setIsViewHtml(false);
          if (onClose) onClose();
        }}
        title={fileName + (isViewHtml ? " - HTML" : " - JSON")}
        showCancelButton={false}
        size="w-8/12"
      >
        <div className="flex flex-col ">
      
          <div className="mt-auto flex justify-end space-x-4">
          <div className="border border-gray-300 rounded-lg relative">
            <select
              // className="appearance-none rounded-lg bg-blue-500 text-white w-full py-2 pr-8 pl-3"
              className="appearance-none px-6 py-2 border border-blue-700 text-blue-600 rounded-lg  focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center"
              id="dropdown"
              value={selectedOption}
              onChange={handleChange}
            >
              <option value="">View Option</option>
              {selectedOption !== 'original_json' && (
                <option value="original_json">Original JSON</option>
              )}
              {(isViewHtml || selectedOption === 'original_json') && (
                <option value="appended_json">Appended Json</option>
              )}
              {!isViewHtml && <option value="html_view">HTML View</option>}
            </select>
            <div className="pointer-events-none absolute inset-y-0 -right-1 flex items-center px-2 text-gray-700">
              <IconChevronDown stroke={1} color="#3b82f6" />
            </div>
          </div>
         
          {!isViewHtml && (
            <>
              <button
                type="button"
                className="px-4 py-2 w-32 border border-blue-700 text-blue-600 rounded-lg hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center"
                onClick={handleEditJson}
              >
                Edit
              </button>
              <button
                type="button"
                className="px-4 py-2 w-32 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
                onClick={handleDownloadJSON}
              >
                Download
              </button>
            </>
          )}
          {isViewHtml && (
            <button
              type="button"
              className="px-4 py-2 w-32 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
              onClick={handleDownloadPDF}
            >
              Download
            </button>
          )}
          <CalculatorPage />
        </div>
      

      </div>
        {!isViewHtml && (
          <div
            className="h-96  items-center justify-between mt-12 overflow-y-scroll "
            style={{
              scrollbarWidth: "thin",
              scrollbarColor: "#3b82f6 #f1f1f1",
            }}
          >
            <div>
              <h1 className="font-bold text-sm">
                {selectedOption === "original_json"
                  ? "Original Json"
                  : "Appended Json"}
              </h1>
              <pre className="p-4 rounded-lg overflow-x-auto whitespace-pre-wrap w-max">
                {jsonData ? JSON.stringify(jsonData, null, 2) : "Loading..."}
              </pre>
            </div>
          </div>
        )}

        {isViewHtml && (
          <div
            className="h-96  items-center justify-between mt-12 overflow-y-scroll "
            style={{
              scrollbarWidth: "thin",
              scrollbarColor: "#3b82f6 #f1f1f1",
            }}
          >
              <div>{parse(htmlData)}</div>
          </div>
        )}
      </Modal>

      <EditJson
        isEditModalOpen={isEditModalOpen}
        onClose={handleClosePopup}
        onCancel={handleCancel}
        handleSave={handleSave}
        fileName={fileName}
        selectedOption={selectedOption}
      />
    </div>
  );
};

export default ViewJson;
