import { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import axios from "axios";
import Papa from "papaparse";

interface CsvRow {
  [key: string]: string;
}

export const EditCsv = ({
  isEditModalOpen,
  onClose,
  onCancel,
  handleSave,
  fileName,
  selectedOption,
}: {
  isEditModalOpen?: boolean;
  onClose: () => void;
  onCancel: () => void;
  handleSave: (updatedData: string) => void;
  fileName?: string;
  selectedOption?: string;
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState<CsvRow[]>([]);
  const [editedData, setEditedData] = useState<CsvRow[]>([]);
  const [version, setVersion] = useState("");

  const parseCSV = (csv: string) => {
    Papa.parse<CsvRow>(csv, {
      header: true,
      dynamicTyping: true,
      complete: (result) => {
        setData(result.data);
        setEditedData(result.data);
      },
      error: (error: any) => {
        console.error("Error parsing CSV:", error);
      },
    });
  };

  const updatedFile = () => {
    const name = fileName + ".csv";
    axios
      .get(`${apiUrl}/view/csv/${name}`, {
        params: {
          version: version,
        },
      })
      .then((response) => {
        parseCSV(response.data);
        // setData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (selectedOption === "original_csv") {
      setVersion("original");
    } else {
      setVersion("appended");
    }
    if (isEditModalOpen) {
      setIsModalOpen(true);
      updatedFile();
    } else {
      setIsModalOpen(false);
    }
  }, [isEditModalOpen, selectedOption]);

  const handleChange = (rowIndex: number, key: string, value: string) => {
    const updatedData = [...editedData];
    updatedData[rowIndex] = { ...updatedData[rowIndex], [key]: value };
    setEditedData(updatedData);
  };

  // Function to handle saving the data
  const handleSaveClick = () => {
    axios
      .patch(`${apiUrl}/csv/edit/${fileName + ".csv"}`, editedData[0], {
        params: {
          row_id: 1,
          version: version,
        },
      })
      .then((response) => {
        console.log(response);
        updatedFile();
      })
      .catch(function (error) {
        console.log(error);
      });

    const updatedCsv = Papa.unparse(editedData);
    handleSave(updatedCsv);
    setIsModalOpen(false);
  };

  const handleCancelClick = () => {
    setIsModalOpen(false);
    onCancel();
  };

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          if (onClose) onClose();
        }}
        title={fileName + " - CSV"}
        showCancelButton={false}
        size="w-8/12"
      >
        <div className="flex flex-col">
          <div className="mt-auto flex justify-end space-x-4">
            <button
              type="button"
              className="px-4 py-2 w-32 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
              onClick={handleSaveClick}
            >
              Save
            </button>
            <button
              type="button"
              className="px-4 py-2 w-32 border border-blue-700 text-blue-600 rounded-lg hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center"
              onClick={handleCancelClick}
            >
              Cancel
            </button>
          </div>
        </div>
        <div className="h-96 items-center justify-between mt-12">
          <div
            className="h-96 items-center justify-between mt-12 overflow-y-scroll"
            style={{
              scrollbarWidth: "thin",
              scrollbarColor: "#3b82f6 #f1f1f1",
            }}
          >
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                {data?.length > 0 && data[0] && (
                  <tr>
                    {Object.keys(data[0]).map((key) => (
                      <th
                        key={key}
                        className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider"
                      >
                        {key}
                      </th>
                    ))}
                  </tr>
                )}
              </thead>

              <tbody className="bg-white divide-y divide-gray-200">
                {/* {editedData.map((row, rowIndex) => ( */}
                {editedData.length > 0 &&
                  editedData
                    ?.filter((row) =>
                      Object.values(row).some(
                        (value) =>
                          value !== null && value !== undefined && value !== ""
                      )
                    )
                    .map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {Object.keys(row).map((key, colIndex) => (
                          <td
                            key={colIndex}
                            className={`px-6 py-4 whitespace-pre-wrap text-sm text-gray-500 border-b border-gray-300 ${
                              colIndex === 0 ? "w-5/6" : "w-11/12"
                            }`}
                          >
                            <textarea
                              value={row[key as keyof CsvRow]}
                              onChange={(e) =>
                                handleChange(rowIndex, key, e.target.value)
                              }
                              className="h-32 px-2 py-1 border border-gray-300 rounded"
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EditCsv;
