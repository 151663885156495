import { useState, useEffect } from "react";
import Modal from "./../../components/Modal";
import ViewJson from "../json/ViewJson";
import { Download } from "../../components/Download";
import axios from "axios";
import ViewCsv from "../csv/ViewCsv";
import ViewXml from "../xml/ViewXml";

export const ConfirmationPopup = ({
  isConvertToJson,
  isConvertTocsv,
  onClose,
  uploadedFileName,
  isConvertToXml
}: {
  isConvertToJson?: boolean;
  isConvertTocsv?: boolean;
  onClose: () => void;
  uploadedFileName?: string;
  isConvertToXml?: boolean;
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState<boolean>(false);
  const [isCsvViewModalOpen, setIsCsvViewModalOpen] = useState(false);
  const [isXmlView, setIsXmlView] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (isConvertToJson || isConvertTocsv || isConvertToXml) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [isConvertToJson, isConvertToXml, isConvertTocsv]);

  const handleView = () => {
    console.log("isConvertTocsv", isConvertTocsv);
    if (isConvertToJson) {
      setIsViewModalOpen(true);
      setIsModalOpen(false);
    }else if (isConvertTocsv) {
      setIsCsvViewModalOpen(true);
      setIsModalOpen(false);
    }else if(isConvertToXml){
      setIsXmlView(true)
      setIsModalOpen(false);
    }
  };

  const handleClosePopup = () => {
    setIsViewModalOpen(false);
    setIsCsvViewModalOpen(false);
    setIsModalOpen(false);
    setIsXmlView(false);
    if (onClose) onClose();
  };

  const handleDownloadFile = async () => {
    if (isConvertToJson) {
      const fileName = uploadedFileName?.split(".")[0] + ".json";
      await axios
        .get(`${apiUrl}/json/download/${fileName}`, {
          params: {
            version: "appended",
          },
        })
        .then((response) => {
          Download(response.data, fileName);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (isConvertTocsv) {
      const fileName = uploadedFileName?.split(".")[0] + ".csv";
      const response = await axios.get(`${apiUrl}/download/csv/${fileName}`, {
        params: {
          version: 'appended', 
        },
        responseType: 'blob', 
      });
      const contentDisposition = response.headers["content-disposition"];
      const filename = contentDisposition
        ? contentDisposition.split("filename=")[1]?.replace(/"/g, "")
        : fileName;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } else if (isConvertToXml) {
      const fileName = uploadedFileName?.split(".")[0] + ".xml";
      const response = await axios.get(`${apiUrl}/download/xml/${fileName}`, {
        params: {
          version: 'appended', 
        },
        responseType: 'blob', 
      });
      if (response.headers["content-type"] === "application/xml") {
        const file = new Blob([response.data], { type: "application/xml" });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = response.headers["content-disposition"]
          ? response.headers["content-disposition"]
              .split("filename=")[1]
              .replace(/['"]/g, "")
          : fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(fileURL);
      } else {
        console.error(
          "Unexpected content type:",
          response.headers["content-type"]
        );
      }
    }
  };


  return (
    <div>
      <div className="p-6">
        <Modal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            if (onClose) onClose();
          }}
          title=" "
          showCancelButton={false}
          size="w-5/12"
        >
          <div className="h-28 items-center justify-between my-4">
            {isConvertToJson && (
              <p className="text-center text-lg">
                File is successfully converted into JSON
              </p>
            ) }
            {isConvertTocsv && (
              <p className="text-center text-lg">
                File is successfully converted into CSV
              </p>
            )}
            {isConvertToXml && (<p className="text-center text-lg">
                File is successfully converted into XML
              </p>)}
            <div className="flex flex-col items-center mt-8">
              <div className="flex space-x-4">
                <button
                  type="button"
                  className="px-4 py-2 w-44 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
                  onClick={handleDownloadFile}
                >
                  Download
                </button>
                <button
                  type="button"
                  className="px-4 py-2 w-44 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
                  onClick={handleView}
                >
                  View
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      <ViewJson
        isViewModalOpen={isViewModalOpen}
        onClose={handleClosePopup}
        // fileData={Data}
        uploadedFileName={uploadedFileName}
      />

      <ViewCsv
        isCsvViewModalOpen={isCsvViewModalOpen}
        onClose={handleClosePopup}
        // fileData={Data}
        uploadedFileName={uploadedFileName}
      />

      <ViewXml isXmlView={isXmlView}  onClose={handleClosePopup} uploadedFileName={uploadedFileName}/>
    </div>
  );
};

export default ConfirmationPopup;
