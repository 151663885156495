import { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import axios from "axios";
import EditCsv from "./EditCsv";
import CsvDataDisplay from "../../components/CsvDataDisplay";
import { IconChevronDown } from "@tabler/icons-react";
import CalculatorPage from "../../components/Calculator";

export const ViewCsv = ({
  isCsvViewModalOpen,
  onClose,
  uploadedFileName,
}: {
  isCsvViewModalOpen?: boolean;
  onClose: () => void;
  uploadedFileName?: string;
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const [csvData, setCSVData] = useState("");
  const [selectedOption, setSelectedOption] = useState<string>("");

  useEffect(() => {
    setSelectedOption('')
    if (isCsvViewModalOpen) {
      setIsModalOpen(true);
      previewFile("appended");
    } else {
      setIsModalOpen(false);
    }
    if (uploadedFileName) {
      const fileName = uploadedFileName.split(".")[0];
      setFileName(fileName);
    }
  }, [apiUrl, fileName, isCsvViewModalOpen, uploadedFileName]);

  const previewFile = (version: string) => {
    axios.get(`${apiUrl}/view/csv/${fileName + ".csv"}`, {
        params: {
          version: version,
        },
      })
      .then((response) => {
        setCSVData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleEdit = () => {
    setIsEditModalOpen(true);
  };

  const handleClosePopup = () => {
    setIsEditModalOpen(false);
    setIsModalOpen(false);
    if (onClose) onClose();
  };

  const handleCancel = () => {
    setIsEditModalOpen(false);
  };

  const handleSave = (updatedData: any) => {
    setCSVData(updatedData);
    setIsEditModalOpen(false);
  };

  const handleDownload = async () => {
    const fileName = uploadedFileName?.split(".")[0] + ".csv";
    if(selectedOption === "original_csv"){
    const response = await axios.get(`${apiUrl}/download/csv/${fileName}`, {
      params: {
        version: 'original', 
      },
      responseType: 'blob', 
    });
    const contentDisposition = response.headers["content-disposition"];
    const filename = contentDisposition
      ? contentDisposition.split("filename=")[1]?.replace(/"/g, "")
      : fileName;
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }else if(selectedOption === "appended_csv" || selectedOption===''){
    const response = await axios.get(`${apiUrl}/download/csv/${fileName}`, {
      params: {
        version: 'appended', 
      },
      responseType: 'blob', 
    });
    const contentDisposition = response.headers["content-disposition"];
    const filename = contentDisposition
      ? contentDisposition.split("filename=")[1]?.replace(/"/g, "")
      : fileName;
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedOption(value);
    switch (value) {
      case "original_csv":
        previewFile("original");
        break;
      case "appended_csv":
        previewFile("appended");
        break;
      default:
        setSelectedOption("");
        break;
    }
  };

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          if (onClose) onClose();
        }}
        title={fileName + " - CSV"}
        showCancelButton={false}
        size="w-8/12"
      >
        <div className="flex flex-col ">
          <div className="mt-auto flex justify-end space-x-4">
            <div className="border border-gray-300 rounded-lg relative">
              <select
                className="appearance-none px-6 py-2 border border-blue-700 text-blue-600 rounded-lg  focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center"
                id="dropdown"
                value={selectedOption}
                onChange={handleChange}
              >
                <option value="">View Option</option>
                {selectedOption === "original_csv" ? (
                  <option value="appended_csv">Appended CSV</option>
                ) : (
                  <option value="original_csv">Original CSV</option>
                )}
              </select>
              <div className="pointer-events-none absolute inset-y-0 -right-1 flex items-center px-2 text-gray-700">
                <IconChevronDown stroke={1} color="#3b82f6" />
              </div>
            </div>
            <button
              type="button"
              className="px-4 py-2 w-32 border border-blue-700 text-blue-600 rounded-lg hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center"
              onClick={handleEdit}
            >
              Edit
            </button>
            <button
              type="button"
              className="px-4 py-2 w-32  bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
              onClick={handleDownload}
            >
              Download
            </button>
            <CalculatorPage />
          </div>
        </div>
        <h1 className="font-bold text-sm mb-4">
              {selectedOption !== "original_csv"
                ? "Appended CSV"
                : "Original CSV"}
            </h1>
        <div
          className="h-96  items-center justify-between mt-12 overflow-y-scroll "
          style={{
            scrollbarWidth: "thin",
            scrollbarColor: "#3b82f6 #f1f1f1",
          }}
        >
          <div>
           
            {csvData ? <CsvDataDisplay csvData={csvData} /> : "Loading..."}
          </div>
        </div>
      </Modal>

      <EditCsv
        isEditModalOpen={isEditModalOpen}
        onClose={handleClosePopup}
        onCancel={handleCancel}
        handleSave={handleSave}
        fileName={fileName}
        selectedOption={selectedOption}
      />
    </div>
  );
};

export default ViewCsv;
