import { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import AceEditor from "react-ace";
import "brace/mode/xml";
import "brace/theme/github";
import axios from "axios";

export const EditXml = ({
  isEditModalOpen,
  onClose,
  onCancel,
  handleSave,
  fileName,
  selectedOption,
}: {
  isEditModalOpen?: boolean;
  onClose: () => void;
  onCancel: () => void;
  handleSave: (updatedData: string) => void;
  fileName?: string;
  selectedOption?: string;
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [xmlData, setXmlData] = useState<string>("");
  const [editableData, setEditableData] = useState<string>("");
  const [version, setVersion] = useState<string>("");

  const updatedFile = () => {
    const name = fileName + ".xml";
    axios
      .get(`${apiUrl}/view/xml/${name}`, { params: { version: version } })
      .then((response) => {
        setXmlData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (selectedOption === "original_xml") {
      setVersion("original");
    } else {
      setVersion("appended");
    }
    if (isEditModalOpen) {
      setIsModalOpen(true);
      updatedFile();
    } else {
      setIsModalOpen(false);
    }
  }, [isEditModalOpen, selectedOption]);

  useEffect(() => {
    setEditableData(xmlData);
  }, [xmlData]);

  const onChange = (newValue: string) => {
    setEditableData(newValue);
  };

  const handleSaveClick = () => {
    const xml = editableData?.replace(/\n/g, "").replace(/\s+/g, " ")?.trim();
    axios
      .patch(`${apiUrl}/xml/edit/${fileName + ".xml"}`, xml, {
        headers: {
          "Content-Type": "application/xml",
        },
        params: { version: version },
      })
      .then((response) => {
        console.log(response);
        updatedFile();
      })
      .catch((error) => {
        console.log(error);
      });

    handleSave(editableData);
    setIsModalOpen(false);
  };

  const handleCancelClick = () => {
    setIsModalOpen(false);
    onCancel();
  };

  const formatXml = (xml: string) => {
    return xml.replace(/</g, "\n<").replace(/\n\n/g, "\n");
  };

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          if (onClose) onClose();
        }}
        title={fileName + " - XML"}
        showCancelButton={false}
        size="w-8/12"
      >
        <div className="flex flex-col">
          <div className="mt-auto flex justify-end space-x-4">
            <button
              type="button"
              className="px-4 py-2 w-32 bg-blue-500 text-white rounded-lg hover:bg-blue-600 flex items-center justify-center"
              onClick={handleSaveClick}
            >
              Save
            </button>
            <button
              type="button"
              className="px-4 py-2 w-32 border border-blue-700 text-blue-600 rounded-lg hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center"
              onClick={handleCancelClick}
            >
              Cancel
            </button>
          </div>
        </div>
        <div className="h-96 w-full items-center justify-between mt-12">
        {/* <div
            className="h-96 w-full items-center justify-between mt-12 overflow-y-scroll"
            style={{
              scrollbarWidth: "thin",
              scrollbarColor: "#3b82f6 #f1f1f1",
            }}
          > */}
          <AceEditor
            mode="xml"
            theme="github"
            onChange={onChange}
            editorProps={{ $blockScrolling: true }}
            value={formatXml(editableData)}
            fontSize={14}
            lineHeight={19}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            setOptions={{
              showGutter: true,
              highlightActiveLine: true,
              fontSize: 14,
            }}
            style={{width:'100%'}}
          />
          {/* </div> */}
        </div>
      </Modal>
    </div>
  );
};

export default EditXml;
